<script setup>
import { storeToRefs } from 'pinia';
import { useMessageStore } from '~/stores/messages';

// Define props
const props = defineProps({
    hours: {
        type: Array,
        default: () => []
    }
});

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Opening hours
const getOpeningHours = computed(() => {
    const validDayOfWeekValues = [1, 2, 3, 4, 5, 6, 7];

    const daysOfWeekRaw = [
        staticKeys.value?.general_day_mon,
        staticKeys.value?.general_day_tue,
        staticKeys.value?.general_day_wed,
        staticKeys.value?.general_day_thu,
        staticKeys.value?.general_day_fri,
        staticKeys.value?.general_day_sat,
        staticKeys.value?.general_day_sun
    ];

    const daysOfWeek = daysOfWeekRaw.map((day) => {
        return `${day?.slice(0, 2)}.`;
    })

    // Check if array exists
    if (!props.hours) {
        return false;
    }

    // Return rearranged result of opening hours
    return props.hours.filter((entry) => {
        // Filter incorrect values
        return entry.dayOfWeek !== null && validDayOfWeekValues.includes(entry.dayOfWeek)
    }).reduce((accumulator, currentValue, index) => {
        // Set index to current day of week (more precise than index)
        index = currentValue.dayOfWeek - 1;

        // Initial push
        if (accumulator.length === 0) {
            if (currentValue.isClosed) {
                accumulator.push({
                    firstDay: daysOfWeek[index],
                    lastDay: daysOfWeek[index],
                    isClosed: true
                });
            } else {
                const data = {
                    firstDay: daysOfWeek[index],
                    lastDay: daysOfWeek[index],
                    isClosed: false
                };

                if (currentValue.operation?.from) {
                    data['from'] = currentValue.operation?.from;
                }

                if (currentValue.operation?.till) {
                    data['till'] = currentValue.operation?.till;
                }

                if (currentValue.operation?.from_ext) {
                    data['from_ext'] = currentValue.operation?.from_ext;
                }

                if (currentValue.operation?.from_ext) {
                    data['till_ext'] = currentValue.operation?.till_ext;
                }

                accumulator.push(data);
            }
        } else {
            // Compare opening hours from last entry with current entry
            const lastItem = accumulator.slice(-1)[0];

            // Check if item is closed
            if (currentValue.isClosed) {
                // Check if last item is also closed
                if (lastItem.isClosed && currentValue.isClosed) {
                    // Assign current day to lastDay property
                    lastItem.lastDay = daysOfWeek[index];
                } else {
                    // Push new entry
                    accumulator.push({
                        firstDay: daysOfWeek[index],
                        lastDay: daysOfWeek[index],
                        isClosed: true
                    });
                }
            } else {
                // Item is not closed
                // Check if last item opening hours are the same as current item
                // eslint-disable-next-line no-lonely-if
                if (
                    lastItem.from === currentValue.operation?.from &&
                    lastItem.till === currentValue.operation?.till &&
                    lastItem.from_ext === currentValue.operation?.from_ext &&
                    lastItem.till_ext === currentValue.operation?.till_ext
                ) {
                    // Assign current day to lastDay property
                    lastItem.lastDay = daysOfWeek[index];
                } else {
                    // Push new entry
                    const data = {
                        firstDay: daysOfWeek[index],
                        lastDay: daysOfWeek[index],
                        isClosed: false
                    }

                    if (currentValue.operation?.from) {
                        data['from'] = currentValue.operation?.from;
                    }

                    if (currentValue.operation?.till) {
                        data['till'] = currentValue.operation?.till;
                    }

                    if (currentValue.operation?.from_ext) {
                        data['from_ext'] = currentValue.operation?.from_ext;
                    }

                    if (currentValue.operation?.from_ext) {
                        data['till_ext'] = currentValue.operation?.till_ext;
                    }

                    accumulator.push(data);
                }
            }
        }

        return accumulator;
    }, []).map((item) => {
        // Do some formatting + refine array
        if (item.isClosed) {
            if (item.firstDay === item.lastDay) {
                item = {
                    day: item.firstDay,
                    isClosed: true
                };
            } else {
                item = {
                    day: `${item.firstDay} - ${item.lastDay}`,
                    isClosed: true
                };
            }
        } else if (item.firstDay === item.lastDay) {
            const data = {
                day: item.firstDay,
                isClosed: false
            }

            if (item?.from && item.till) {
                data['openingHours'] = `${item.from} - ${item.till}`;
            }

            if (item?.from_ext && item.till_ext) {
                data['openingHours_ext'] = `${item.from_ext} - ${item.till_ext}`;
            }

            item = data;
        } else {
            const data = {
                day: `${item.firstDay} - ${item.lastDay}`,
                isClosed: false
            }

            if (item?.from && item.till) {
                data['openingHours'] = `${item.from} - ${item.till}`;
            }

            if (item?.from_ext && item.till_ext) {
                data['openingHours_ext'] = `${item.from_ext} - ${item.till_ext}`;
            }

            item = data;
        }

        return item;
    });
})
</script>

<template>
    <table class="opening-hours-table">
        <tbody>
            <tr v-for="day in getOpeningHours" :key="day.day">
                <template v-if="day.isClosed">
                    <td>{{ day.day }}</td>
                    <td>{{ staticKeys?.general_closed }}</td>
                </template>
                <template v-else>
                    <td>{{ day.day }}</td>
                    <td>
                        {{ day.openingHours }}
                        <span v-if="day.openingHours_ext">
                            {{ day.openingHours_ext }}
                        </span>
                    </td>
                </template>
            </tr>
        </tbody>
    </table>
</template>

<style lang="scss">
.opening-hours-table {
    @include body2-medium;

    width: 100%;

    .shop-opening-hours-container & {
        @include body3-medium;

        margin-left: 30px;
        width: auto;

        @include bp(medium1) {
            @include body2-medium;

            margin-left: 40px;
        }

        @include bp(large1) {
            @include h4-medium;
        }

        @include bp(large2) {
            margin-left: 40px;
        }
    }

    td {
        padding: 0 0 15px;
        vertical-align: top;

        &:first-child {
            padding: 0 20px 15px 0;
            white-space: nowrap;
            width: 1px;

            @include bp(large2) {
                padding: 0 60px 15px 0;
            }
        }

        &:last-child {
            width: 100%;
            text-align: right;

            .shop-opening-hours-container & {
                width: auto;
                text-align: left;
            }
        }

        span {
            display: block;
        }

    }

    tr {
        &:last-child {
            td {
                padding: 0;

                &:first-child {
                    padding: 0 20px 0 0;

                    @include bp(large2) {
                        padding: 0 60px 0 0;
                    }

                }
            }
        }
    }
}
</style>
